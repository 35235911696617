import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://22dee9e9bc9f185b4885f4cab2c6d262@o416619.ingest.sentry.io/4506579442925568",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});